import React, { useEffect } from "react";
import '../../styles/ScrollingMessage.css';

export default function ScrollingMessage() {
    useEffect(() => {
        const message = document.querySelector('.scrolling-message');
        let containerWidth = document.querySelector('.scrolling-container').offsetWidth;
        let messageWidth = message.offsetWidth;
        let startPos = containerWidth;
        let endPos = -messageWidth;
        let speed = 3;

        message.style.position = 'relative';
        message.style.left = `${startPos}px`;

        function updatePosition() {
            startPos -= speed;

            if (startPos < endPos) {
                startPos = containerWidth;
            }

            message.style.left = `${startPos}px`;
            requestAnimationFrame(updatePosition);
        }

        updatePosition();
    }, []);

    return (
        <div className="scrolling-container">
            <div className="scrolling-message">
                <h1>ENJOY THE RIDE, IT'S OVER.  NEXT STOP : ???</h1>
            </div>
        </div>
    );
}
