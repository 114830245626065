import '../../styles/Landing.css';
import React from 'react';
import logo from '../../assets/images/spinning_fest.gif';
import frame from '../../assets/images/cadre_gif.png';
import gif from '../../assets/images/asset.gif';
import ScrollingMessage from './ScrollingMessage';

export default function Landing() {
    return (
        <div className="Landing">
            <ScrollingMessage />
            <img src={gif} alt='gif' style={gifStyle} />
            <img src={frame} alt='frame' style={frameStyle} />
            <img src={logo} alt='logo' style={logoStyle} />
        </div>
    );
}

const logoStyle = {
    width: "auto",
    height: "15vh",
    position: "absolute",
    bottom: "1%",
    left: "50%",
    transform: "translateX(-50%)"

}

const frameStyle = {
    width: "auto",
    height: "60vh",
    top: "12%",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)"
}

const gifStyle = {
    width: "auto",
    height: "45vh",
    top: "20%",
    position: "absolute",
    left: "51%",
    transform: "translateX(-50%)"
}